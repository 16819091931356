import store from "@/store";

export default [
    {
        path: '/clientes',
        name: 'clientes.index',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/clientes/index.vue'),
        meta: {
            title: 'Clientes'
        }
    },
    {
        path: '/clientes/:id',
        name: 'clientes.ver',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/clientes/ver.vue'),
        meta: {
            title: 'Ver Cliente'
        }
    },
]
