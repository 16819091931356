import {exibeErro} from "@/functions";
import VuexPersistence from 'vuex-persist'
import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import router from "@/router";

//Módulos
import PaymentMethods from './modules/formas-pagamento';
import Papeis from './modules/papeis';
import Produtos from './modules/produtos';
import Pedidos from './modules/pedidos';
import Usuarios from './modules/usuarios';
import Clientes from './modules/clientes';

//Aux
import _ from "lodash";

const vuexLocal = new VuexPersistence({storage: window.localStorage});
export default createStore(Store, {
	state: {
		user: undefined,
	},

	modules: {
		'orders': Pedidos,
		'payment_methods': PaymentMethods,
		'products': Produtos,
		'roles': Papeis,
		'users': Usuarios,
		'customers': Clientes,
	},

	mutations: {
		setUser: (state, user) => {
			state.user = user;
		},
	},

	actions: {
		login: async function(context, credentials) {
			console.debug(`Entrou na action login`);

			try {
				await window.axios.get(process.env.VUE_APP_API_URL + '/sanctum/csrf-cookie');
				await window.axios.post(process.env.VUE_APP_API_URL + '/login', credentials);
				await router.push({name: 'dashboard'});

			} catch(error) {
				exibeErro(error);
			}
		},

		async logout({ commit }) {
			console.debug(`Entrou na action logout`);

			try {
				await window.axios.post(process.env.VUE_APP_API_URL + '/logout');
				await commit(`setUser`, undefined);
				await window.localStorage.clear();
				window.location = '/login';

			} catch(error) {
				exibeErro(error);
			}
		},

		async check({/*state, */dispatch, commit}, next) {
			console.debug(`Entrou na action check`);

			// if (state.user) {
			// 	next();
			//
			// } else {
				try {
					const me = (await dispatch(`users/me`, null, {root:true})).data;
					await commit(`setUser`, me);

					// const permissions = me.permissions.reduce((result, item) => {
					// 	result[item.name] = true;
					// 	return result;
					// }, {});
					// await commit(`setPermissions`, permissions);

					// const settings_data = (await dispatch(`settings/index`, {}, {root:true})).data;
					// let settings = settings_data.data.reduce((result, item) => {
					// 	result[item.key] = item.value;
					// 	return result;
					// }, {});
					// await commit(`setSettings`, settings);

					next();

				} catch(error) {
					if (Object.prototype.hasOwnProperty.call(error, 'response') && error.response.status === 401)
						await dispatch(`logout`);

					else {
						exibeErro(error);
					}
				}
			// }
		}
	},

	mixins: {
		state: {
			data: [],
			fetching: false
		},

		mutations: {
			setFetching: (state, status) => {
				state.fetching = status;
			},
			setData: (state, data) => {
				state.data = data;
			},

			//Paginação
			setCurrentPage: (state, page) => {
				state.pagination.current_page = page;
			},
			setLastPage: (state, page) => {
				state.pagination.last_page = page;
			},

			//Filtros
			setFilter: (state, value) => {
				state.filtering = value;
			},

			setFilteringKeyword: (state, value) => {
				state.filtering.keyword = value;
			},
		},

		actions: {
			index: async ({state}, params) => {
				console.debug('Entrou na action index.');

				const searchParams = new URLSearchParams(Object.assign({}, window.defaultQueryParams, params));
				return await window.axios.get(process.env.VUE_APP_API_URL + `/${state.name}?${searchParams.toString()}`);
			},

			delete: async ({commit, state}, params) => {
				console.debug('Entrou na action delete.');
				await window.axios.delete(process.env.VUE_APP_API_URL + `/${state.name}/${params.id}`);

				let data = state.data;
				data.splice(params.index,1);

				commit('setData', data);
			},

			get: async ({state}, params) => {
				console.debug('Entrou na action get.');

				const searchParams = new URLSearchParams(Object.assign({}, window.defaultQueryParams, params.query));
				return await window.axios.get(process.env.VUE_APP_API_URL + `/${state.name}/${params.id}?${searchParams.toString()}`);
			},

			patch: async ({state}, params) => {
				console.debug('Entrou na action patch.');

				let query = _.get(params,'query') ? params.query : '';
				const searchParams = new URLSearchParams(Object.assign({}, window.defaultQueryParams, query));
				return await window.axios.patch(process.env.VUE_APP_API_URL + `/${state.name}/${params.id}?${searchParams.toString()}`, params.data);
			},

			post: async ({state}, params) => {
				console.debug('Entrou na action post.');

				const searchParams = new URLSearchParams(Object.assign({}, window.defaultQueryParams, params.query));
				return await window.axios.post(process.env.VUE_APP_API_URL + `/${state.name}?${searchParams.toString()}`, params.data);
			},

			reset({ commit }) {
				console.debug('Entrou na action reset.');

				commit('setData',undefined);
				commit('setCurrentPage', 0);
				commit('setLastPage', undefined);
			},

			fetch({ dispatch, commit, state }, initial = false) {
				console.debug('Entrou na action fetch.');

				if (initial)
					dispatch(`reset`);

				let nextPage = state.pagination.current_page + 1;

				if (state.pagination.last_page === undefined || nextPage <= state.pagination.last_page) {
					commit('setFetching', true);

					let searchParams = {
						page: nextPage,
						sort: (state.sorting.selected.direction === 'asc' ? '' : '-') + state.sorting.selected.field
					};
					searchParams[`filter[${state.filtering.selected.field}]`] = state.filtering.keyword;

					return dispatch(`index`, searchParams)
						.then(function (response) {
							commit('setData',
								state.data === undefined
									? response.data.data
									: state.data.concat(response.data.data)
							);
							commit('setCurrentPage', response.data.current_page);
							commit('setLastPage', response.data.last_page);
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
							commit('setFetching', false);
						});

				} else {
					console.debug('Já foi feito fetch da última página.');
				}
			},

			//Filtros
			setFilteringKeyword({ commit }, value) {
				console.debug('Entrou na action setFilteringKeyword.');

				commit('setFilteringKeyword', value);
			},

			setFilter({ commit }, value) {
				console.debug('Entrou na action setFilter.');

				commit('setFilter', value);
			},

			//Paginação
			setCurrentPage({ commit }, value) {
				console.debug('Entrou na action setCurrentPage.');

				commit('setCurrentPage', value);
			},
		}
	},

	plugins: [vuexLocal.plugin]
});
