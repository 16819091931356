import store from "@/store";

export default [
    {
        path: '/formas-pagamento',
        name: 'formas-pagamento.index',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/formas-pagamento/index.vue'),
        meta: {
            title: 'Formas de Pagamento'
        }
    },
    {
        path: '/formas-pagamento/:id',
        name: 'formas-pagamento.ver',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/formas-pagamento/ver.vue'),
        meta: {
            title: 'Ver Forma de Pagamento'
        }
    },
]
