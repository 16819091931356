import {alertController, toastController} from "@ionic/vue";
import _ from "lodash";
import store from "@/store";

const openToast = async function (message, duration = 2000) {
    console.debug(message);

    const toast = await toastController
        .create({
            message: message,
            duration: duration
        })
    return toast.present();
};

const presentAlert = async function (title, html) {
    const alert = await alertController
        .create({
            header: title,
            message: html,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        console.log('Confirm Okay')
                    },
                },
            ],
        });
    return alert.present();
}

const presentAlertConfirm = async function(title, html, okCallback, cancelCallback) {
    const alert = await alertController
        .create({
            header: title,
            message: html,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: cancelCallback,
                },
                {
                    text: 'OK',
                    handler: okCallback,
                },
            ],
        });
    return alert.present();
}

const break_line_to_br = function (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

const exibeErro = async function (error) {
    console.error(error);

    if (Object.prototype.hasOwnProperty.call(error, 'response')) {
        // if (_.get(error, 'constructor.name') === "Error") {
        const message = _.get(error.response.data, 'message');
        const errors = _.get(error.response.data, 'errors', []);

        //Para quando retorna 403 sem uma mensagem
        if (error.response.status === 403/* && (message.trim() === "" || !message)*/) {
            presentAlert('Oops...', 'Proibido!');

            //Para quando retorna 401 sem uma mensagem
        } else if (error.response.status === 401/* && (message.trim() === "" || !message)*/) {
            await openToast('Não autenticado! Você está sendo redirecionado para o login...');
            await store.dispatch(`logout`);

        } else {
            presentAlert('Oops...', Object.entries(errors).reduce(
                function (ac, error) {
                    return ac += `<span style="text-decoration: underline">${error[0]}</span>:<br> ${error[1].reduce(
                        (ac2, item) => ac2 += `* ${item}<br>`,
                        ""
                    )}`;
                },
                `<strong>${break_line_to_br(message)}</strong><br>`
            ));
        }

    } else {
        openToast(error)
    }
};

const toCurrency = function(value, currency = "BRL", precision = 2) {
    return Number
        .parseFloat(value)
        .toLocaleString('pt-BR', {
            currency: currency,
            style:"currency",
            minimumFractionDigits: precision
        });
};

const appVersion = function() {
    return `${process.env.VUE_APP_MAJOR_VERSION}.${process.env.VUE_APP_MINOR_VERSION}`;
}

export {openToast, exibeErro, presentAlert, presentAlertConfirm, toCurrency, appVersion};
