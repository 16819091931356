import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/museo-sans-font-import.css';
import './theme/trueno-font-import.css';
import './theme/variables.css';

//Vuex
import store from "@/store";

//Utils
const _ = require('lodash');

import VueTranslate from 'vue-simple-translator';
import translations from '@/translations.json';

//PWA
import './registerServiceWorker'

window.axios = require("axios");
window.axios.defaults.withCredentials = true;

if (process.env.NODE_ENV === 'development') {
    window.defaultQueryParams = {"XDEBUG_SESSION_START": ""};
}

const app = createApp(App)
    .use(IonicVue)
    .use(store)
    .use(router)
    .use(VueTranslate, {
        availableLanguages: {
            pt_BR: 'Português do Brasil'
        },
        defaultLanguage: 'pt_BR',
        translations: translations
    })
    .directive("lowercase", {
        updated: function (el) {
            if (_.get(el, 'value'))
                el.value = el.value.toLowerCase()
        }
    })
    .directive("uppercase", {
        updated: function (el) {
            if (_.get(el, 'value'))
                el.value = el.value.toUpperCase()
        }
    });

app.config.globalProperties.$_ = _;

router.isReady().then(() => {
    app.mount('#app');
});
