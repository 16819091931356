import store from "@/store";

export default [
    {
        path: '/usuarios',
        name: 'usuarios.index',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/usuarios/index.vue'),
        meta: {
            title: 'Usuários'
        }
    },
    {
        path: '/usuarios/novo',
        name: 'usuarios.novo',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/usuarios/ver.vue'),
        meta: {
            title: 'Novo Usuário'
        }
    },
    {
        path: '/usuarios/:id',
        name: 'usuarios.ver',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/usuarios/ver.vue'),
        meta: {
            title: 'Ver Usuário'
        }
    },
]
