import store from "@/store";

export default [
    {
        path: '/produtos',
        name: 'produtos.index',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/produtos/index.vue'),
        meta: {
            title: 'Produtos'
        }
    },
    {
        path: '/produtos/:id',
        name: 'produtos.ver',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/produtos/ver.vue'),
        meta: {
            title: 'Ver Produto'
        }
    },
]
