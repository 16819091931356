import {createRouter, createWebHistory} from '@ionic/vue-router';

import Clientes from './routes/clientes';
import FormasPagamento from './routes/formas-pagamento';
import Papeis from './routes/papeis';
import Pedidos from './routes/pedidos';
import Produtos from './routes/produtos';
import Usuarios from './routes/usuarios';
import store from "@/store";

const routes = [
    {
        path: '',
        name: 'dashboard',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/dashboard/index.vue'),
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import ('@/views/login/index.vue'),
        meta: {
            title: 'Entrar'
        }
    },
    ...Clientes,
    ...FormasPagamento,
    ...Papeis,
    ...Pedidos,
    ...Produtos,
    ...Usuarios,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
