<template>
    <ion-app :class="$_.get($store.state.user,'font_size') === 'big' ? 'fonte-grande' : 'fonte-normal'">
        <ion-split-pane content-id="main-content">
            <ion-menu content-id="main-content" type="overlay" v-if="$store.state.user">
                <ion-content>
                    <ion-list id="inbox-list">
                        <ion-list-header>{{ $store.state.user.name }}</ion-list-header>
                        <ion-note>{{ $store.state.user.email }}</ion-note>

                        <ion-menu-toggle
                            auto-hide="false"
                            v-for="(p, i) in appPages"
                            :key="i"
                        >
                            <ion-item
                                @click="selectedIndex = i"
                                router-direction="root"
                                :router-link="p.url"
                                lines="none"
                                detail="false"
                                class="hydrated"
                                :class="{ selected: selectedIndex === i }"
                            >
                                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                                <ion-label>{{ p.title }}</ion-label>
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle auto-hide="false">
                            <ion-item
                                @click="logout"
                                router-direction="root"
                                lines="none"
                                detail="false"
                                class="hydrated"
                            >
                                <ion-icon slot="start" :ios="logOutOutline" :md="logOutSharp"></ion-icon>
                                <ion-label>Sair</ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>

<!--                    <ion-list id="labels-list">-->
<!--                        <ion-list-header>Labels</ion-list-header>-->

<!--                        <ion-item v-for="(label, index) in labels" lines="none" :key="index">-->
<!--                            <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>-->
<!--                            <ion-label>{{ label }}</ion-label>-->
<!--                        </ion-item>-->
<!--                    </ion-list>-->
                </ion-content>

                <ion-footer class="ion-text-center">
                    Versão: {{ appVersion() }}
                </ion-footer>
            </ion-menu>

            <ion-router-outlet id="main-content"></ion-router-outlet>
        </ion-split-pane>
    </ion-app>
</template>

<script>
import {
    IonApp,
    IonContent, IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
} from '@ionic/vue';
    import {defineComponent, ref} from 'vue';
    import {useRoute} from 'vue-router';

    //ícones
    import {
        bookmarkSharp,
        bookmarkOutline,
        paperPlaneSharp,
        paperPlaneOutline,
        peopleSharp,
        peopleOutline,
        lockOpenSharp,
        lockOpenOutline,
        homeSharp,
        homeOutline,
        cartSharp,
        cartOutline,
        logOutSharp,
        logOutOutline,
        pricetagsOutline,
        pricetagsSharp,
        documentLockOutline,
        documentLockSharp,
        cashOutline,
        cashSharp,
    } from 'ionicons/icons';
import {appVersion} from "@/functions";

    export default defineComponent({
        name: 'App',

        components: {
            IonApp,
            IonContent,
            IonIcon,
            IonItem,
            IonLabel,
            IonList,
            IonListHeader,
            IonMenu,
            IonMenuToggle,
            IonNote,
            IonRouterOutlet,
            IonSplitPane,
            IonFooter
        },

        methods: {
            appVersion: appVersion,

            logout() {
                this.$store.dispatch(`logout`);
            },
        },

        setup() {
            const selectedIndex = ref(0);
            const appPages = [
                {
                    title: 'Dashboard',
                    url: '/',
                    iosIcon: homeOutline,
                    mdIcon: homeSharp
                },
                {
                    title: 'Pedidos',
                    url: '/pedidos',
                    iosIcon: cartOutline,
                    mdIcon: cartSharp
                },
                {
                    title: 'Clientes',
                    url: '/clientes',
                    iosIcon: peopleOutline,
                    mdIcon: peopleSharp
                },
                {
                    title: 'Formas de Pagamento',
                    url: '/formas-pagamento',
                    iosIcon: cashOutline,
                    mdIcon: cashSharp
                },
                {
                    title: 'Produtos',
                    url: '/produtos',
                    iosIcon: pricetagsOutline,
                    mdIcon: pricetagsSharp,
                },
                {
                    title: 'Papéis',
                    url: '/papeis',
                    iosIcon: documentLockOutline,
                    mdIcon: documentLockSharp
                },
                {
                    title: 'Usuários',
                    url: '/usuarios',
                    iosIcon: peopleOutline,
                    mdIcon: peopleSharp
                }
            ];
            // const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

            const path = window.location.pathname.split('folder/')[1];
            if (path !== undefined) {
                selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
            }

            const route = useRoute();

            return {
                selectedIndex,
                appPages,
                // labels,
                bookmarkOutline,
                bookmarkSharp,
                lockOpenSharp,
                lockOpenOutline,
                paperPlaneOutline,
                paperPlaneSharp,
                logOutSharp,
                logOutOutline,
                pricetagsOutline,
                pricetagsSharp,
                documentLockOutline,
                documentLockSharp,
                cashOutline,
                cashSharp,
                isSelected: (url) => url === route.path ? 'selected' : ''
            }
        }
    });
</script>

<style>
    /* Retira a sombra de baixo do header no Android */
    .header-md::after {
        display: none;
    }

    #container {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #container strong {
        font-size: 20px;
        line-height: 26px;
    }

    #container p {
        font-size: 16px;
        line-height: 22px;
        color: #8c8c8c;
        margin: 0;
    }

    #container a {
        text-decoration: none;
    }

    .middle {
        display: flex;
        align-items: center;
    }
</style>

<style scoped>
    ion-menu ion-content {
        --background: var(--ion-item-background, var(--ion-background-color, #fff));
    }

    ion-menu.md ion-content {
        --padding-start: 15px;
        --padding-end: 15px;
        --padding-top: 15px;
        --padding-bottom: 15px;
    }

    ion-menu.md ion-list {
        padding: 20px 0;
    }

    ion-menu.md ion-note {
        margin-bottom: 30px;
    }

    ion-menu.md ion-list-header,
    ion-menu.md ion-note {
        padding-left: 10px;
    }

    ion-menu.md ion-list#inbox-list {
        /*border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);*/
    }

    ion-menu.md ion-list#inbox-list ion-list-header {
        font-size: 22px;
        font-weight: 600;
        min-height: 20px;
    }

    ion-menu.md ion-list#labels-list ion-list-header {
        font-size: 16px;

        margin-bottom: 18px;

        color: #757575;

        min-height: 26px;
    }

    ion-menu.md ion-item {
        --padding-start: 10px;
        --padding-end: 10px;
        border-radius: 4px;
    }

    ion-menu.md ion-item.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);
    }

    ion-menu.md ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-menu.md ion-item ion-icon {
        color: #616e7e;
        margin-inline-end: 20px;
    }

    ion-menu.md ion-item ion-label {
        font-weight: 500;
    }

    ion-app.fonte-grande ion-menu.md ion-item ion-label {
        font-size: 1.5em;
    }

    ion-app.fonte-normal ion-menu.md ion-item ion-label {
        font-size: 17px;
    }

    ion-menu.ios ion-content {
        --padding-bottom: 20px;
    }

    ion-menu.ios ion-list {
        padding: 20px 0 0 0;
    }

    ion-menu.ios ion-note {
        line-height: 24px;
        margin-bottom: 20px;
    }

    ion-menu.ios ion-item {
        --padding-start: 16px;
        --padding-end: 16px;
        --min-height: 50px;
    }

    ion-menu.ios ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-menu.ios ion-item ion-icon {
        font-size: 24px;
        color: #73849a;
    }

    ion-menu.ios ion-list#labels-list ion-list-header {
        margin-bottom: 8px;
    }

    ion-menu.ios ion-list-header,
    ion-menu.ios ion-note {
        padding-left: 16px;
        padding-right: 16px;
    }

    ion-menu.ios ion-note {
        margin-bottom: 8px;
    }

    ion-note {
        display: inline-block;
        font-size: 16px;

        color: var(--ion-color-medium-shade);
    }

    ion-item.selected {
        --color: var(--ion-color-primary);
    }
</style>
