import store from "@/store";

export default [
    {
        path: '/pedidos',
        name: 'pedidos.index',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/pedidos/index.vue'),
        meta: {
            title: 'Pedidos'
        }
    },
    {
        path: '/pedidos/novo',
        name: 'pedidos.novo',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/pedidos/ver.vue'),
        meta: {
            title: 'Novo Pedido'
        }
    },
    {
        path: '/pedidos/:id',
        name: 'pedidos.ver',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/pedidos/ver.vue'),
        meta: {
            title: 'Ver Pedido'
        }
    },
]
