export default {
    namespaced: true,

    state: {
        name: 'orders',

        fields: [
            {
                name: "Atualizado em",
                value: 'updated_at'
            },
            {
                name: "Criado em",
                value: 'created_at'
            },
            {
                name: "ID",
                value: 'id'
            },
        ],

        //Filtros
        filtering: {
            keyword: "",

            selected: {
                field: 'id',
                criteria: ""
            },
        },

        //Ordenação
        sorting: {
            selected: {
                field: 'id',
                direction: 'desc'
            },

            // directions: [ //fixme precisa realmente disso? Me parece desnecessário, só há 2 direções sempre
            //     {
            //         name: "Crescente",
            //         value: 'asc'
            //     },
            //     {
            //         name: "Decrescente",
            //         value: 'desc'
            //     }
            // ]
        },

        //Paginação
        pagination: {
            current_page: 0,
            last_page: undefined,
        },
    }
}
