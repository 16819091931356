import store from "@/store";

export default [
    {
        path: '/papeis',
        name: 'papeis.index',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/papeis/index.vue'),
        meta: {
            title: 'Papéis'
        }
    },
    {
        path: '/papeis/novo',
        name: 'papeis.novo',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/papeis/ver.vue'),
        meta: {
            title: 'Novo Papel'
        }
    },
    {
        path: '/papeis/:id',
        name: 'papeis.ver',
        beforeEnter: (to, from, next) => store.dispatch('check', next),
        component: () => import ('@/views/papeis/ver.vue'),
        meta: {
            title: 'Ver Papel'
        }
    },
]
