export default {
    namespaced: true,

    state: {
        name: 'products',

        fields: [
            {
                name: "ID",
                value: 'CODG_PRDT'
            },
            {
                name: "Nome",
                value: 'NOME_PRDT'
            },
        ],

        //Filtros
        filtering: {
            keyword: "",

            selected: {
                field: 'NOME_PRDT',
                criteria: ""
            },
        },

        //Ordenação
        sorting: {
            selected: {
                field: 'NOME_PRDT',
                direction: 'asc'
            },
        },

        //Paginação
        pagination: {
            current_page: 0,
            last_page: undefined,
        },
    }
}
