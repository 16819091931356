export default {
    namespaced: true,

    state: {
        name: 'users',

        fields: [
            {
                name: "Atualizado em",
                value: 'updated_at'
            },
            {
                name: "Criado em",
                value: 'created_at'
            },
            {
                name: "ID",
                value: 'id'
            },
        ],

        //Filtros
        filtering: {
            keyword: "",

            selected: {
                field: 'id',
                criteria: ""
            },
        },

        //Ordenação
        sorting: {
            selected: {
                field: 'id',
                direction: 'desc'
            },
        },

        //Paginação
        pagination: {
            current_page: 0,
            last_page: undefined,
        },
    },

    actions: {
        me: ({state}) => {
            console.debug('Entrou na action me.');

            return window.axios.get(process.env.VUE_APP_API_URL + `/${state.name}/me`)
        }
    }
}
