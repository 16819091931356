/* eslint-disable no-console */

import {register} from 'register-service-worker'
import {openToast} from "@/functions";

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'O aplicativo está sendo servido do cache por um service worker.\n' +
                'Para mais detalhes, visite https://goo.gl/AFskqB'
            )
        },

        registered() {
            console.log('Service worker foi registrado.')
        },

        cached() {
            console.log('O conteúdo foi armazenado em cache para uso offline.')
        },

        updatefound() {
            openToast('Nova versão está sendo baixada...');
        },

        updated() {
            openToast('Nova versão disponível. Atualizando...');
            window.location.reload(true)
        },

        offline() {
            console.log('Nenhuma conexão de internet encontrada. O aplicativo está sendo executado no modo offline.')
        },

        error(error) {
            console.error('Erro durante o registro do service worker:', error)
        }
    })
}
